<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import QRCode from "qrcodejs2";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("menuitems.devices.devicedetail"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      devicelist: [],
      devicesearch: "",
      getdeviceslist: "",
      selectdevicestatus: "-1",
      jurisdiction: [],
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      deviceid: "",
      devicesdetail: [],
      setting: [],
      userinfo: [],
      qrcode: "",
      deviceserver: [],
      loading: true,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.devices.devicedetail");
    this.items = [
      {
        text: this.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.devices.lists"),
        href: "/device/list",
      },
      {
        text: this.$t("menuitems.devices.devicedetail"),
        active: true,
      },
    ];
    this.jurisdiction = JSON.parse(sessionStorage.getItem("jurisdiction"));
    this.activeapp = JSON.parse(sessionStorage.getItem("apps"));
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.getdeviceinfo();
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.deviceid = this.$route.query.id;
  },
  methods: {
    getdeviceinfo() {
      var that = this;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "getdeivces",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading=false
          that.devicesdetail = response.data.data;
          that.setting = response.data.settingv2;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    copyLink: function (dom) {
      let _this = this;
      let clipboard = new this.clipboard("." + dom);
      clipboard.on("success", function () {
        _this.$toast(_this.$t("global.copy.success"));
      });
      clipboard.on("error", function () {
        _this.$toast(_this.$t("global.copy.fail"));
      });
    },
    opendoor: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "gethdinfo",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          that
            .$prompt(
              that.$t("device.detail.pleaseenterhd", {
                number: response.data.data.device.hd_num,
              }),
              that.$t("global.waring"),
              {
                confirmButtonText: that.$t("global.button.ok"),
                cancelButtonText: that.$t("global.button.cancel"),
                inputType: "number",
                inputPattern: /^[0-9]+.?[0-9]*$/,
                inputErrorMessage: that.$t("device.detail.pleaseinputtrue"),
              }
            )
            .then(({ value }) => {
              if (value > 0 && value <= response.data.data.device.hd_num) {
                that.$axios
                  .post(
                    "https://admin.aicoiot.beer/api.json",
                    {
                      action: "opendoor",
                      id: id,
                      hd: value,
                    },
                    {
                      headers: { Openid: that.userinfo.openid },
                    }
                  )
                  .then(function (response) {
                    that.$toast.clear();
                    if (response.data.status == 200) {
                      that.$message({
                        message: that.$t("global.success"),
                        type: "success",
                      });
                    } else if (response.data.status == 207) {
                      that.$message.error(that.$t("device.lists.offline"));
                    } else {
                      that.$message.error(that.$t("global.delete.500"));
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              } else {
                that.$message.error(that.$t("device.detail.pleaseinputtrue"));
              }
            })
            .catch(() => {});
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    closedoor: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "gethdinfo",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          that
            .$prompt(
              that.$t("device.detail.pleaseenterhd", {
                number: response.data.data.device.hd_num,
              }),
              that.$t("global.waring"),
              {
                confirmButtonText: that.$t("global.button.ok"),
                cancelButtonText: that.$t("global.button.cancel"),
                inputType: "number",
                inputPattern: /^[0-9]+.?[0-9]*$/,
                inputErrorMessage: that.$t("device.detail.pleaseinputtrue"),
              }
            )
            .then(({ value }) => {
              if (value > 0 && value <= response.data.data.device.hd_num) {
                that.$axios
                  .post(
                    "https://admin.aicoiot.beer/api.json",
                    {
                      action: "closedoor",
                      id: id,
                      hd: value,
                    },
                    {
                      headers: { Openid: that.userinfo.openid },
                    }
                  )
                  .then(function (response) {
                    that.$toast.clear();
                    if (response.data.status == 200) {
                      that.$message({
                        message: that.$t("global.success"),
                        type: "success",
                      });
                    } else if (response.data.status == 207) {
                      that.$message.error(that.$t("device.lists.offline"));
                    } else {
                      that.$message.error(that.$t("global.delete.500"));
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              } else {
                that.$message.error(that.$t("device.detail.pleaseinputtrue"));
              }
            })
            .catch(() => {});
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    restart: function (id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.waring"),
          that.$t("device.detail.restarttip"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              "https://admin.aicoiot.beer/api.json",
              {
                action: "restart",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.$toast.clear();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else if (response.data.status == 207) {
                that.$message.error(that.$t("device.lists.offline"));
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    maintenance: function (action, id) {
      var that = this;
      if (action == "open") {
        var tips = that.$t("device.detail.openmaintenance");
      } else {
        var tips = that.$t("device.detail.closemaintenance");
      }
      that
        .$confirm(that.$t("global.waring"), tips, {
          confirmButtonText: that.$t("global.button.ok"),
          cancelButtonText: that.$t("global.button.cancel"),
          type: "warning",
        })
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              "https://admin.aicoiot.beer/api.json",
              {
                action: "maintenance",
                jofs: action,
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.$toast.clear();
              that.getdeviceinfo();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else if (response.data.status == 207) {
                that.$message.error(that.$t("device.lists.offline"));
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    showstockresetlog: function (id) {},
    showmaintainlog: function (id) {},
    getwechatqrcode: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });

      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "getwechatqrcode",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.$alert(
              "<img src='https://paygay.aic.xin/qr.png?data=" +
                response.data.img +
                "' width='100%'/>",
              that.$t("device.button.qrcode"),
              {
                dangerouslyUseHTMLString: true,
              }
            );
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showdeviceqrdiy: function () {
      var that = this;
      that.$alert(
        "<img src='https://paygay.aic.xin/qr.png?data=https://mdy.aicoiot.beer/user" +
          that.userinfo.id +
          ".html?sn=" +
          that.devicesdetail.device_sn +
          "' width='100%'/>",
        that.$t("device.button.qrcode"),
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },
    showdeviceqr: function () {
      var that = this;
      that.$alert(
        "<img src='https://paygay.aic.xin/qr.png?data=https://menu.aicoiot.beer/" +
          that.devicesdetail.device_sn +
          ".memu' width='300px' height='300px'/>",
        that.$t("device.button.qrcode"),
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },
    opensharing: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });

      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "switchshring",
            type: "open",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.getdeviceinfo();
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    closesharing: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });

      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "switchshring",
            type: "close",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.getdeviceinfo();
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    complex: function (id) {},
    itialization: function (id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.waring"),
          that.$t("device.detail.itialization"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              "https://admin.aicoiot.beer/api.json",
              {
                action: "itialization",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.$toast.clear();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else {
                that.$message.error(that.$t("global.failed"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    opensafedoor: function (id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.waring"),
          that.$t("device.detail.openthesafedoor"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              "https://admin.aicoiot.beer/api.json",
              {
                action: "opensafedoor",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.$toast.clear();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else {
                that.$message.error(that.$t("global.failed"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    changemode: function (id, type) {
      var that = this;
      if (type == "pay") {
        var modetext = that.$t("device.detail.switchpaymentmode");
      } else {
        var modetext = that.$t("device.detail.switchhandemode");
      }
      that
        .$confirm(modetext, that.$t("global.waring"), {
          confirmButtonText: that.$t("global.button.ok"),
          cancelButtonText: that.$t("global.button.cancel"),
          type: "warning",
        })
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              "https://admin.aicoiot.beer/api.json",
              { action: "changemode", ED_id: id, type: type },
              { headers: { Openid: that.userinfo.openid } }
            )
            .then(function (response) {
              that.$toast.clear();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else {
                that.$message.error(that.$t("global.failed"));
              }
            });
        });
    },
    changedeviceserver: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });

      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "getnowserver",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.deviceserver = response.data.data;
            that.$alert("", that.$t("device.detail.changeserver"), {
              dangerouslyUseHTMLString: true,
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getipinfos: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "getclientip",
            edid: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.deviceserver = response.data.data;
            that.$alert(
              that.$t("device.detail.country") +
                "：" +
                response.data.data.country +
                "<br/>" +
                that.$t("device.detail.area") +
                "：" +
                response.data.data.area +
                "<br/>" +
                that.$t("device.detail.region") +
                "：" +
                response.data.data.region +
                "<br/>" +
                that.$t("device.detail.city") +
                "：" +
                response.data.data.city +
                "<br/>" +
                that.$t("device.detail.county") +
                "：" +
                response.data.data.county +
                "<br/>" +
                that.$t("device.detail.isp") +
                "：" +
                response.data.data.isp +
                "<br/>" +
                that.$t("device.detail.ip") +
                "：" +
                response.data.data.ip,
              that.$t("device.detail.ip"),
              {
                dangerouslyUseHTMLString: true,
              }
            );
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    commanddebug: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "getdeviceserror",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.$toast.loading({
              message: that.$t("global.loading.wating"),
              forbidClick: true,
            });
            setTimeout(function () {
              that.getdeviceinfo();
              that.$toast.clear();
            }, 5000);
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <div class="col-lg-6" style="float: left">
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.id") }}</span>
                <span
                  class="float-right text-muted copyid"
                  :data-clipboard-text="devicesdetail.ED_id"
                  @click="copyLink('copyid')"
                  >{{ devicesdetail.ED_id }}</span
                >
              </p>
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.sn") }}</span>
                <span
                  class="float-right text-muted copysn"
                  :data-clipboard-text="devicesdetail.device_sn"
                  @click="copyLink('copysn')"
                  >{{ devicesdetail.device_sn }}</span
                >
              </p>
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.name") }}</span>
                <span class="float-right text-muted">{{
                  devicesdetail.name
                }}</span>
              </p>
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.mark") }}</span>
                <span class="float-right text-muted">{{
                  devicesdetail.remark
                }}</span>
              </p>
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.note") }}</span>
                <span class="float-right text-muted">{{
                  devicesdetail.note
                }}</span>
              </p>

              <p class="clearfix" v-if="devicesdetail.subid != 7100">
                <span class="float-left">{{
                  $t("device.detail.default_pay")
                }}</span>
                <span class="float-right" v-if="devicesdetail.default_pay == 2"
                  ><i class="fab fa-alipay" style="color: #4dabf7"></i
                  >{{ $t("device.orders.payment.alipay") }}</span
                >
                <span class="float-right" v-if="devicesdetail.default_pay == 3"
                  ><i class="fab fa-weixin" style="color: #3cb034"></i
                  >{{ $t("device.orders.payment.wxpay") }}</span
                >
                <span class="float-right" v-if="devicesdetail.default_pay == 4"
                  ><i class="fab fa-apple-pay"></i
                  >{{ $t("device.orders.payment.applepay") }}</span
                >
              </p>
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.error") }}</span>
                <span class="float-right">
                  <label
                    class="badge badge-danger"
                    v-if="devicesdetail.error_code == 31"
                    >{{ $t("device.error.error31") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 32"
                    >{{ $t("device.error.error32") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 33"
                    >{{ $t("device.error.error33") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 34"
                    >{{ $t("device.error.error34") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 35"
                    >{{ $t("device.error.error35") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 36"
                    >{{ $t("device.error.error36") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 37"
                    >{{ $t("device.error.error37") }}</label
                  >
                  <label class="badge badge-success" v-else>{{
                    $t("device.error.true")
                  }}</label>
                </span>
              </p>
            </div>
            <div class="col-lg-6 button-items" style="float: left">
              <!-- 7200 -->
              <div v-if="devicesdetail.subid == 7200">
                <button
                  class="btn btn-info mr-2"
                  @click="opendoor(devicesdetail.id)"
                  v-if="jurisdiction.indexOf('devices-info-opendoor') >= 0"
                >
                  <i class="mdi mdi-flash mr-2"></i>
                  {{ $t("device.button.openvavl") }}
                </button>
                <button
                  class="btn btn-info mr-2"
                  @click="closedoor(devicesdetail.id)"
                  v-if="jurisdiction.indexOf('devices-info-closedoor') >= 0"
                >
                  <i class="mdi mdi-flash-off mr-2"></i>
                  {{ $t("device.button.closevavl") }}
                </button>
              </div>
              <!-- 7100 -->
              <div v-else-if="devicesdetail.subid == 7100"></div>
              <!-- 7620 -->
              <div v-else-if="devicesdetail.subid == 7620">
                <button
                  class="btn btn-info mr-2"
                  @click="opendoor(devicesdetail.id)"
                  v-if="jurisdiction.indexOf('devices-info-opendoor') >= 0"
                >
                  <i class="mdi mdi-flash mr-2"></i>
                  {{ $t("device.button.openvavl") }}
                </button>
                <button
                  class="btn btn-info mr-2"
                  @click="closedoor(devicesdetail.id)"
                  v-if="jurisdiction.indexOf('devices-info-closedoor') >= 0"
                >
                  <i class="mdi mdi-flash-off mr-2"></i>
                  {{ $t("device.button.closevavl") }}
                </button>
                <br />
                <router-link
                  :to="{ path: '/device/hd', query: { id: devicesdetail.id } }"
                >
                  <a
                    class="btn btn-info"
                    v-if="jurisdiction.indexOf('devices-info-freight') >= 0"
                  >
                    {{ $t("device.button.hdmanage") }}
                  </a>
                </router-link>
                <router-link
                  :to="{
                    path: '/device/stock',
                    query: { id: devicesdetail.id },
                  }"
                >
                  <a
                    class="btn btn-info btn-fwo"
                    v-if="jurisdiction.indexOf('devices-info-stock') >= 0"
                  >
                    {{ $t("device.button.stockmanage") }}
                  </a>
                </router-link>
                <br />
                <button
                  class="btn btn-info mr-2"
                  @click="itialization(devicesdetail.id)"
                  v-if="jurisdiction.indexOf('devices-info-closedoor') >= 0"
                >
                  {{ $t("device.button.startup") }}
                </button>
                <button
                  class="btn btn-info mr-2"
                  @click="opensafedoor(devicesdetail.id)"
                  v-if="jurisdiction.indexOf('devices-info-closedoor') >= 0"
                >
                  {{ $t("device.button.opendoor") }}
                </button>
                <br />
                <b-dropdown variant="secondary">
                  <template v-slot:button-content>
                    {{ $t("device.button.other") }}
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="changemode(devicesdetail.ED_id, 'pay')"
                    >{{ $t("device.button.modechange") }}-{{
                      $t("device.button.payment")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="changemode(devicesdetail.ED_id, 'hande')"
                    >{{ $t("device.button.modechange") }}-{{
                      $t("device.button.handmode")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="changelang(devicesdetail.ED_id, 1)"
                    >{{ $t("device.button.language") }}-{{
                      $t("device.button.chs")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="changelang(devicesdetail.ED_id, 2)"
                    >{{ $t("device.button.language") }}-{{
                      $t("device.button.en")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="expow(devicesdetail.ED_id)"
                    >{{ $t("device.button.setting") }}-{{
                      $t("device.button.sealpower")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="expre(devicesdetail.ED_id)"
                    >{{ $t("device.button.setting") }}-{{
                      $t("device.button.pressureout")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="inpre(devicesdetail.ED_id)"
                    >{{ $t("device.button.setting") }}-{{
                      $t("device.button.pressurein")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="refushsetting(devicesdetail.ED_id)"
                    >{{ $t("device.button.setting") }}-{{
                      $t("device.button.refresh")
                    }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <!-- Else -->
              <div v-else>
                <button
                  class="btn btn-success"
                  @click="opendoor(devicesdetail.id)"
                  v-if="jurisdiction.indexOf('devices-info-opendoor') >= 0"
                >
                  <i class="mdi mdi-flash mr-2"></i>
                  {{ $t("device.button.openvavl") }}
                </button>
                <button
                  class="btn btn-success"
                  @click="closedoor(devicesdetail.id)"
                  v-if="jurisdiction.indexOf('devices-info-closedoor') >= 0"
                >
                  <i class="mdi mdi-flash-off mr-2"></i>
                  {{ $t("device.button.closevavl") }}
                </button>
                <br />
                <router-link
                  :to="{ path: '/device/hd', query: { id: devicesdetail.id } }"
                >
                  <a
                    class="btn btn-info"
                    v-if="jurisdiction.indexOf('devices-info-freight') >= 0"
                  >
                    {{ $t("device.button.hdmanage") }}
                  </a>
                </router-link>
                <router-link
                  :to="{ path: '/device/ads', query: { id: devicesdetail.id } }"
                >
                  <a class="btn btn-info" @click="managead(devicesdetail.id)">
                    {{ $t("device.button.adsmanage") }}
                  </a>
                </router-link>
                <router-link
                  :to="{
                    path: '/device/stock',
                    query: { id: devicesdetail.id },
                  }"
                >
                  <a
                    class="btn btn-info btn-fwo"
                    v-if="jurisdiction.indexOf('devices-info-stock') >= 0"
                  >
                    {{ $t("device.button.stockmanage") }}
                  </a>
                </router-link>
                <br />
                <button
                  class="btn btn-warning"
                  @click="restart(devicesdetail.id)"
                >
                  <i class="mdi mdi-backup-restore mr-2"></i>
                  {{ $t("device.button.restart") }}
                </button>
                <button
                  class="btn btn-warning"
                  @click="maintenance('open', devicesdetail.id)"
                  v-if="devicesdetail.status == 1"
                >
                  {{ $t("device.button.uphold") }}
                </button>
                <button
                  class="btn btn-success"
                  @click="maintenance('close', devicesdetail.id)"
                  v-if="devicesdetail.status == 5"
                >
                  {{ $t("device.button.downhold") }}
                </button>
              </div>
              <a
                href="javascript:;"
                class="btn btn-primary"
                @click="showstockresetlog(devicesdetail.id)"
                v-if="devicesdetail.subid == 7620"
              >
                {{ $t("device.button.stockresetlog") }}
              </a>
              <a
                href="javascript:;"
                class="btn btn-secondary"
                @click="showmaintainlog(devicesdetail.id)"
                v-if="devicesdetail.subid == 7620"
              >
                {{ $t("device.button.holdlogs") }}
              </a>
              <a
                href="javascript:;"
                class="btn btn-success"
                @click="getwechatqrcode(devicesdetail.id)"
                v-if="
                  activeapp.indexOf('wechatsetting') >= 0 &&
                  jurisdiction.indexOf('devices-info-qrcode') >= 0
                "
              >
                <i class="mdi mdi-wechat"></i>
                {{ $t("device.button.wechatqrcode") }}
              </a>
              <a
                href="javascript:;"
                class="btn btn-info"
                @click="showdeviceqrdiy"
                v-if="
                  userinfo.id == devicesdetail.uid &&
                  activeapp.indexOf('paymentpagediy') >= 0 &&
                  jurisdiction.indexOf('devices-info-qrcode') >= 0
                "
              >
                <i class="mdi mdi-qrcode"></i>
                {{ $t("device.button.qrcode") }}
              </a>
              <a
                href="javascript:;"
                class="btn btn-info"
                @click="showdeviceqr"
                v-else
              >
                <i class="mdi mdi-qrcode"></i>
                {{ $t("device.button.qrcode") }}
              </a>
              <br />
              <a
                href="javascript:;"
                class="btn btn-success btn-fw"
                @click="opensharing(devicesdetail.id)"
                v-if="
                  (devicesdetail.uid == userinfo.id ||
                    devicesdetail.topuid == userinfo.id) &&
                  activeapp.indexOf('separate') >= 0 &&
                  devicesdetail.sharing == 0
                "
              >
                {{ $t("device.button.sharingopen") }}
              </a>
              <a
                href="javascript:;"
                class="btn btn-danger btn-fw"
                @click="closesharing(devicesdetail.id)"
                v-if="
                  (devicesdetail.uid == userinfo.id ||
                    devicesdetail.topuid == userinfo.id) &&
                  activeapp.indexOf('separate') >= 0 &&
                  devicesdetail.sharing >= 1
                "
              >
                {{ $t("device.button.sharingclose") }}
              </a>
              <a
                href="javascript:;"
                class="btn btn-info btn-fw"
                v-if="
                  (devicesdetail.uid == userinfo.id ||
                    devicesdetail.topuid == userinfo.id) &&
                  activeapp.indexOf('socket.notify.complex') >= 0 &&
                  jurisdiction.indexOf('devices-info-stock') >= 0
                "
                @click="complex(devicesdetail.id)"
              >
                {{ $t("device.button.nofitysetting") }}
              </a>
              <br />
              <a
                href="javascript:;"
                class="btn btn-info"
                v-if="
                  jurisdiction.indexOf('system-console-show') >= 0 &&
                  devicesdetail.subid != 7620
                "
              >
                <i class="mdi mdi-stethoscope"></i>
                {{ $t("device.button.diagnose") }}
              </a>
              <a
                href="javascript:;"
                class="btn btn-info"
                v-if="
                  jurisdiction.indexOf('system-console-show') >= 0 &&
                  devicesdetail.subid == 7620
                "
                @click="commanddebug(devicesdetail.id)"
              >
                <i class="mdi mdi-stethoscope"></i>
                {{ $t("device.button.diagnose") }}
              </a>
              <a
                href="javascript:;"
                class="btn btn-primary"
                v-if="jurisdiction.indexOf('system-console-show') >= 0"
                @click="getipinfos(devicesdetail.ED_id)"
              >
                <i class="mdi mdi-tablet-ipad"></i>
                {{ $t("device.button.deviceip") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>